<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">Vind dé specialist in uw regio</h2>

        <span class="card__text">
          Vergelijk offertes voordat u beslist en bespaar tot 40%!
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext"> Vergelijk 6 verhuizers in uw regio </span>
      </div>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Verhuis offertes snel gratis vergelijken.',
      headDescription:
        'Vergelijk verhuisbedrijven op verhuisvergelijker.be. Snel, betrouwbaar en alleen offertes uit uw regio. Bespaar tot wel 40% op verhuiskosten.',
      path: '/',
      usps: [
        {
          title: 'Beantwoord een paar vragen',
          text: 'U vertelt ons wat u zoekt, hierdoor kunnen wij de juiste specialist uit uw regio aan jou koppelen.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Ontvang gratis offertes',
          text: 'U ontvangt maximaal zes offertes van specialisten die aan uw behoeften voldoen. Hiermee bespaart u tot 40%!',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Kies de juiste specialist',
          text: 'Wij hebben onze specialisten al gecheckt, u hoeft alleen maar de offertes te vergelijken en te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
    }
  },
}
</script>
